import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import BookItem from "./blog/BookItem";

const Agb = () => {

    const books = useStaticQuery(graphql`
    {
        allMarkdownRemark {
        nodes {
            frontmatter {
                title
                tag
                slug
                excerpt
                link
                price
                pages
                claim
                features
                language
                image {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                }
            }
            html
            }
        }
    }
    `)

    const allBooks = books.allMarkdownRemark.nodes.map((item, index) => (
        <BookItem
            key={index}
            slug={item.frontmatter.slug}
            alt={item.frontmatter.title}
            title={item.frontmatter.title}
            tag={item.frontmatter.tag}
            link={item.frontmatter.link}
            excerpt={item.frontmatter.excerpt}
            image={item.frontmatter.image}
            price={item.frontmatter.price}
            claim={item.frontmatter.claim}
            features={item.frontmatter.features}
            pages={item.frontmatter.pages}
            language={item.frontmatter.language}
        />
    ))

    return (
        <div>
            <section className="bg-blue-200 text-gray-800 pt-16 pb-10 text-left">
                <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0">
                    <h1 className="font-semibold md:text-4xl text-2xl pb-4">Allgemeine Geschäftsbedingungen</h1>
                    <p className="lg:text-2xl sm:text-lg text-sm">für den Onlineshop der Espresso Tutorials GmbH</p>
                </div>
            </section>
            <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0 flex flex-row flex-wrap py-10">
                <div className="w-full md:w-content md:pr-16 lg:pr-24">
                    <div className="w-full mt-9 article">
                        <div className="prose lg:prose-xl">
                            <p><strong>Paragraph 1 Allgemeines</strong><br/>
                                (1) Der Verkäufer bietet den Kunden im Onlineshop http://shop.espresso-tutorials.com neue Waren, vor allem , zum Kauf an.</p>
                            <p>(2) Beim Einkauf im Online-Shop kommt ein Kaufvertrag durch die Annahme der Bestellung des Kunden durch den Verkäufer zustande. Preisauszeichnungen im Online-Shop stellen kein Angebot im Rechtssinne dar. Der Eingang und die Annahme der Bestellung werden dem Kunden per E-Mail bestätigt.</p>
                            <p>(3) Die Espresso Tutorials GmbH (genannt: Verlag) verkauft über das Internet überwiegend digitale Produkte im In- und Ausland in elektronischer Form (als epub-, pdf-, oder mobi-Dateien etc.) als eBooks, Hör-Bücher und Hör-Bilderbücher (genannt eBuch). Zum Abspielen/Anhören derartiger eBücher muss in manchen Fällen eine entsprechende Software installiert sein, wofür der Kunde selbst Sorge zu tragen hat. Mit dem Kauf eines eBuches des Verlags anerkennt der Kunde das Urhebergesetz (UrhG) und verpflichtet sich, die Copyrights des Verlags und seiner Autoren zu achten und zu schützen.</p>
                            <p>(4) Mit dem Kauf eines eBuches räumt der Verlag dem Kunden ein einfaches und nicht übertragbares Nutzungsrecht zum persönlichen und alleinigen Gebrauch ein. Dem Kunden ist jede Verbreitung, Veröffentlichung, Vervielfältigung oder gewerbsmässige Nutzung untersagt, er kann aber zu Sicherungszwecken eine persönliche Kopie herzustellen.</p>
                            <p>(5) Die Vertragssprache ist deutsch.</p>
                            <p>(6) Gerichtsstand ist Gleichen, soweit der Kunde Kaufmann ist oder eine juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen. Dasselbe gilt, wenn ein Kunde keinen allgemeinen Gerichtsstand in Deutschland hat oder der Wohnsitz oder gewöhnlichen Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt sind.</p>
                            <p><strong>Paragraph 2 Vertragsschluss, Zahlungsbedingungen und Lieferung</strong><br/>
                                (1) eBuch und andere digitale Produkte (1.1) Ein Kunde schliesst seinen Bestellvorgang durch den ‘virtuellen Gang’ zur Kasse ab und unterbreitet damit dem Verlag ein verbindliches Kaufangebot. Preisauszeichnungen im Online-Shop stellen kein Angebot im Rechtssinne dar. (1.2) Der Vertrag kommt durch die Annahme der Bestellung des Kunden durch den Verlag zustande. Vor verbindlicher Abgabe seiner Bestellung kann der Kunde alle Eingaben laufend über die üblichen Tastatur- und Mausfunktionen korrigieren. Darüber hinaus werden alle Eingaben vor verbindlicher Abgabe der Bestellung noch einmal in einem Bestätigungsfenster angezeigt und können auch dort mittels der üblichen Tastatur- und Mausfunktionen korrigiert werden. Der Eingang und die Annahme der Bestellung werden dem Kunden per E-Mail bestätigt. (1.3) Mit der Auftragsbestätigung übersendet der Verkäufer dem Kunden den Vertragstext sowie diese Allgemeine Geschäftsbedingungen. (1.4) Je nach Wahl der Zahlungsart erhält der Kunde nach tatsächlichem Zahlungseingang einen Download-Code per E-Mail, mit dem er das gewünschte eBuch downloaden kann. (1.5) Nach Zahlungseingang (Vorkasse) hat der Kunde Anspruch auf alsbaldige Lieferung. Der Kunde erhält dazu per E-Mail den benötigten Download-Code. Für den Download an sich ist der Kunde selbst verantwortlich. (1.6) Macht ein Kunde glaubhaft, dass der Download nicht funktioniert hat, erhält er nach &amp;UUML;berprüfung des Serverprotokolls solange einen neuen Download-Code, bis die Lieferung sichergestellt ist.</p>
                            <p>(2) Gedruckte Bücher (2.1) Die bestellten Waren werden, sofern vertraglich nicht abweichend vereinbart, an die vom Kunden angegebene Adresse geliefert. Die Lieferung erfolgt aus dem Lager des Verkäufers.</p>
                            <p>(2.2) Die Verfügbarkeit der einzelnen Waren ist in den Artikelbeschreibungen angegeben. Am Lager vorhandene Ware versendet der Verkäufer, sofern nicht ausdrücklich abweichend vereinbart, innerhalb von 3 Werktagen nach Vertragsschluss (bei Vorkasse durch &amp;UUML;berweisung: innerhalb von 3 Werktagen nach Zahlungseingang). Ist bei einem Verkauf über den Online-Shop die Ware als nicht vorrätig gekennzeichnet, so bemüht sich der Verkäufer um eine schnellstmögliche Lieferung. Angaben des Verkäufers zur Lieferfrist sind unverbindlich, sofern nicht ausnahmsweise der Liefertermin vom Verkäufer verbindlich zugesagt wurde.</p>
                            <p>(2.3) Der Verkäufer behält sich vor, eine Teillieferung vorzunehmen, sofern dies für eine zügige Abwicklung vorteilhaft erscheint und die Teillieferung für den Kunden nicht ausnahmsweise unzumutbar ist. Durch Teillieferungen entstehende Mehrkosten werden dem Kunden nicht in Rechnung gestellt.</p>
                            <p>(2.4) Der Verkäufer behält sich vor, sich von der Verpflichtung zur Erfüllung des Vertrages zu lösen, wenn die Ware durch einen Lieferanten zum Tag der Auslieferung anzuliefern ist und die Anlieferung ganz oder teilweise unterbleibt. Dieser Selbstbelieferungsvorbehalt gilt nur dann, wenn der Verkäufer das Ausbleiben der Anlieferung nicht zu vertreten hat. Der Verkäufer hat das Ausbleiben der Leistung nicht zu vertreten, soweit rechtzeitig mit dem Zulieferer ein sog. kongruentes Deckungsgeschäft zur Erfüllung der Vertragspflichten abgeschlossen wurde. Wird die Ware nicht geliefert, wird der Verkäufer den Kunden unverzüglich über diesen Umstand informieren und einen bereits gezahlten Kaufpreis sowie Versandkosten erstatten. Die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung der Ware geht mit der &amp;UUML;bergabe auf den Kunden über. Ist der Kunde Unternehmer, so geht beim Versendungskauf die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung der Ware sowie die Verzögerungsgefahr bereits mit Auslieferung der Ware an den Spediteur, den Frachtführer oder die sonst zur Ausführung der Versendung bestimmte Person über.</p>
                            <p>(2.5) Im Falle der Ausübung des Widerrufsrechts trägt der Kunde die Kosten der Rücksendung.</p>
                            <p>(2.6) Die gelieferten Waren bleiben bis zur Erfüllung aller Forderungen aus dem Vertrag im Eigentum des Verkäufers; im Fall, dass der Kunde eine juristische Person des öffentlichen Rechts, ein öffentlich-rechtliches Sondervermögen oder ein Unternehmer in Ausübung seiner gewerblichen oder selbstständigen beruflichen Tätigkeit ist, auch darüber hinaus aus der laufenden Geschäftsbeziehung bis zum Ausgleich aller Forderungen, die dem Verkäufer im Zusammenhang mit dem Vertrag zustehen.</p>
                            <p>(3) Besondere Hinweise Da der Download von ebooks, Software oder anderen lediglich elektronischen Dateien aufgrund seiner Beschaffenheit nicht für eine Rücksendung geeignet ist, haben Sie in diesen Fällen nach Paragraph 312 IV Nr. 1 BGB kein Widerrufsrecht</p>
                            <p><strong>Paragraph 3 Widerrufsbelehrungen für Verbraucher</strong><br/>
                                (1) Widerrufsbelehrung für den Kauf von Downloads (ebooks, Software,…) und die digitale SAP-Bibliothek</p>
                            <p>Widerrufsrecht Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</p>
                            <p>Espresso Tutorials GmbH<br/>
                                Bahnhofstrasse 2<br/>
                                37130 Gleichen<br/>
                                Telefon: 0551 / 790 89 480<br/>
                                Telefax: 0551 790 894 81<br/>
                                E-Mail: info@espresso-tutorials.com</p>
                            <p>mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
                            <p>Folgen des Widerrufs<br/>
                                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschliesslich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>
                            <p>Ende der Widerrufsbelehrung für den Kauf von Downloads (ebooks, Software,…)</p>
                            <p>(2) Widerrufsbelehrung für den Kauf von Printbuchs und anderen Versandwaren</p>
                            <p>Widerrufsrecht<br/>
                                Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.<br/>
                                Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag</p>
                            <p>– an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat;<br/>
                                – an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Teilsendung oder das letzte Stück in Besitz genommen haben bzw. hat;</p>
                            <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns<br/>
                                Espresso Tutorials GmbH<br/>
                                Bahnhofstrasse 2<br/>
                                37130 Gleichen<br/>
                                Telefon: 0551 / 79 75 7926<br/>
                                Telefax: 03212 / 14 25 566<br/>
                                E-Mail: info@espresso-tutorials.com</p>
                            <p>mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
                            <p>Folgen des Widerrufs Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschliesslich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.</p>
                            <p>Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.</p>
                            <p>Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.</p>
                            <p>Ende der Widerrufsbelehrung für den Kauf von Printbuchs und anderen Versandwaren</p>
                            <p>(2) Die Preise verstehen sich inklusive der Versandkosten. Bei Bestellungen ausserhalb des Online-Shops (s. Paragraph 2 Abs. 2.3) gelten die im Angebot der Verkäuferin angegebenen Preise, ebenfalls inklusive der Versandkosten.</p>
                            <p>(3) Es gelten jeweils die bei den e-Büchern bzw. digitalen Produkten genannten Preise (Brutto-Preis incl. MwSt) Für die Lieferung per Download fallen keine Versandkosten an.</p>
                            <p>(4) Die Belieferung der Kunden durch die die Verkäuferin erfolgt nach Wunsch des Kunden gegen folgende Zahlungsmethoden: Sofortüberweisung, PayPal, Kreditkarte und Vorkasse. Sollten Sie eine Bestellung auf Rechnung benötigen, senden Sie uns bitte eine E-Mail an info[at]espresso-tutorials.com. Anschliessend ist die Option “Vorkasse” im Webshop auszuwählen.</p>
                            <p>(5) Kommt ein Kunde mit seinen Zahlungsverpflichtungen in Verzug, so kann der Verkäufer Schadensersatz nach den gesetzlichen Bestimmungen verlangen und / oder vom Vertrag zurücktreten.</p>
                            <p>(6) Der Verkäufer stellt dem Kunden stets eine Rechnung aus, die ihm bei Lieferung der Ware ausgehändigt wird oder sonst in Textform zugeht.</p>
                            <p><strong>Paragraph 4 Abonnement für die digitale SAP-Bibliothek</strong></p>
                            <p>(1) Der Vertrag über die digitale SAP-Bibliothek hat eine Laufzeit von einem Jahr ab Vertragsschluss. Dieser Vertrag verlängert sich um ein Jahr, wenn er nicht mit einer Frist von 3 Monaten vor Ende des Vertragesjahres gekündigt wird.</p>
                            <p>(2) Die Kündigung kann schriftlich per Post, Per-Mail oder per Telefax erklärt werden. Massgeblich ist das Datum des Zugangs bei espresso-tutorials.</p>
                            <p><strong>Paragraph 5 Haftung für Sach- und Rechtsmängel</strong><br/>
                                (1) Soweit Mängel vorliegen, stehen dem Kunden nach Massgabe der folgenden Bestimmungen die gesetzlichen Gewährleistungsrechte zu. Sind an dem Vertrag nur Kaufleute beteiligt, so gelten ergänzend die Paragraph Paragraph 377 ff. HGB.</p>
                            <p>(2) Schäden, die durch unsachgemässe Handlungen des Kunden bei Aufstellung, Anschluss, Bedienung oder Lagerung der Ware hervorgerufen werden, begründen keinen Gewährleistungsanspruch gegen den Verkäufer. Hinweise zur ordnungsgemässen Behandlung kann der Kunde den Herstellerbeschreibungen entnehmen.</p>
                            <p>(3) Mängel sind vom Kunden innerhalb einer Gewährleistungsfrist von zwei Jahren bei neuen Sachen bzw. von einem Jahr bei gebrauchten Sachen gegenüber dem Verkäufer zu rügen. Ist der Kunde Unternehmer, so beträgt die Gewährleistungsfrist bei neuen Sachen ein Jahr. Bei gebrauchten Sachen ist die Gewährleistung gegenüber Unternehmern ausgeschlossen. Die vorstehenden Haftungsbeschränkungen gelten nicht, soweit der Verkäufer einen Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Ware übernommen hat. Die vorstehenden Haftungsbeschränkungen gelten auch nicht für Schadensersatzansprüche des Kunden, die auf Ersatz eines Köper- oder Gesundheitsschadens wegen eines vom Verkäufer zu vertretenden Mangels gerichtet oder die auf vorsätzliches oder grob fahrlässiges Verschulden des Verkäufers oder seiner Erfüllungsgehilfen gestützt sind. Die vorstehenden Verkürzungen gelten nicht für Mängel eines Bauwerks oder einer Sache, die entsprechend ihrer üblichen Verwendungsweise für ein Bauwerk verwendet worden ist und dessen Mangelhaftigkeit verursacht hat. Die vorstehenden Verkürzungen gelten auch nicht, soweit der Verkäufer einen Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Ware übernommen hat, und nicht für Schadensersatzansprüche des Kunden, die auf Ersatz eines Körper- oder Gesundheitsschadens wegen eines vom Verkäufer zu vertretenden Mangels gerichtet oder die auf vorsätzliches oder grob fahrlässiges Verschulden des Verkäufers oder seiner Erfüllungsgehilfen gestützt sind.</p>
                            <p>(4) Liegen Mängel vor und wurden diese rechtzeitig geltend gemacht, ist der Verkäufer zur Nacherfüllung berechtigt. Schlägt die Nacherfüllung fehl, ist der Kunde berechtigt, den Kaufpreis zu mindern oder vom Vertrag zurückzutreten. Im &amp;UUML;brigen gelten die gesetzlichen Bestimmungen.</p>
                            <p><strong>Paragraph 6 Informationspflichten bei Transportschäden</strong><br/>
                                Werden Waren mit offensichtlichen Schäden an der Verpackung oder am Inhalt angeliefert, so soll der Kunde dies unbeschadet seiner Gewährleistungsrechte (Paragraph 7) sofort beim Spediteur/Frachtdienst reklamieren und unverzüglich durch eine E-Mail oder auf sonstige Weise (Fax/Post) mit dem Verkäufer Kontakt aufnehmen, damit dieser etwaige Rechte gegenüber dem Spediteur/ Frachtdienst wahren kann.</p>
                            <p><strong>Paragraph 7 Haftungsausschluss</strong><br/>
                                (1) Der Verlag übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Alle Angebote sind freibleibend und unverbindlich. Der Verlag behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung und/oder die Lieferung zeitweise auszusetzen oder endgültig einzustellen.</p>
                            <p>(2) Ausserhalb der Haftung für Sach- und Rechtsmängel haftet die Verkäuferin unbeschränkt, soweit die Schadensursache auf Vorsatz oder grober Fahrlässigkeit beruht. Sie haftet auch für die leicht fahrlässige Verletzung von wesentlichen Pflichten (Pflichten, deren Verletzung die Erreichung des Vertragszwecks gefährdet) sowie für die Verletzung von Kardinalpflichten (Pflichten, deren Erfüllung die ordnungsgemässe Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Kunde regelmässig vertraut), jedoch jeweils nur für den vorhersehbaren, vertragstypischen Schaden. Für die leicht fahrlässige Verletzung anderer als der vorstehenden Pflichten haftet die Verkäuferin nicht.</p>
                            <p>(3) Die Haftungsbeschränkungen des vorstehenden Absatzes gelten nicht bei der Verletzung von Leben, Körper und Gesundheit, für einen Mangel nach &amp;UUML;bernahme einer Garantie für die Beschaffenheit des Produktes und bei arglistig verschwiegenen Mängeln. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.</p>
                            <p>(4) Ist die Haftung der Verkäuferin ausgeschlossen oder beschränkt, so gilt dies ebenfalls für die persönliche Haftung ihrer Angestellten, Vertreter und Erfüllungsgehilfen.</p>
                            <p><strong>Paragraph 8 Datenschutz</strong><br/>
                                (1) Dem Kunden ist bekannt und er willigt darin ein, dass die zur Abwicklung des Auftrags erforderlichen persönlichen Daten vom Verkäufer auf Datenträgern gespeichert werden. Der Kunde stimmt der Erhebung, Verarbeitung und Nutzung seiner personenbezogenen Daten ausdrücklich zu. Die gespeicherten persönlichen Daten werden vom Verkäufer selbstverständlich vertraulich behandelt. Die Erhebung, Verarbeitung und Nutzung der personenbezogenen Daten des Kunden erfolgt unter Beachtung des Bundesdatenschutzgesetzes (BDSG) und des Telemediengesetzes (TMG).</p>
                            <p>(2) Dem Kunden steht das Recht zu, seine Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen. Der Verkäufer ist in diesem Fall zur sofortigen Löschung der persönlichen Daten des Kunden verpflichtet. Bei laufenden Bestellvorgängen erfolgt die Löschung nach Abschluss des Bestellvorgangs.</p>
                            <p>(3) Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (Google) Google Analytics verwendet sog. Cookies, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über Ihre Benutzung diese Website (einschliesslich Ihrer IP-Adresse) wird an einen Server der Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.</p>
                        </div>
                    </div>
                </div>
                <div className="w-full w-sidebar mt-12 md:mt-0">
                    <h2 className="font-bold text-gray-800 text-2xl">Unsere Bücher</h2>
                    <div className="mt-8">
                        {allBooks}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Agb
